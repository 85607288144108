//
// position.scss
// Extended from Bootstrap
//

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
