//
// badge.scss
// Extended from Bootstrap
//

@mixin badge-variant-soft($color, $badge-soft-bg-opacity) {
  background-color: rgba($color, $badge-soft-bg-opacity);
  color: $color;

  &[href]:hover,
  &[href]:focus {
    background-color: rgba($color, $badge-soft-bg-opacity * 2);
    color: $color;
  }
}