//
// grid.scsss
// Extended from Bootstrap
//

[class^="container"] {
  position: relative;
  z-index: 1;
  padding-right: $grid-gutter-width / 2;
  padding-left: $grid-gutter-width / 2;
}

[class^="col"] {
  position: relative;
}
