//
// card.scss
// Extended from Bootstrap
//

// Card resets

.card {
  position: relative;
  width: 100%;
}

.card-header {
  background-color: transparent;
  border-bottom: $border-width solid $border-color;
}

.card-body {
  display: block;
  flex-grow: 0;
}

.card-footer {
  background-color: unset;
}

.card-body, .card-footer, .card-meta, .card-img, .card-img-top, .card-img-start, .card-img-end {
  position: relative;
  min-height: 1px;
}


// Card action

a.card, a.card-body, a.card-footer, a.card-meta {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}


// Card flush

.card-flush {
  background-color: unset;
}

.card-flush > *:not(.card-btn) {
  padding-left: 0;
  padding-right: 0;
}


// Card border

.card-border {
  position: relative;
}

.card-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  border-top-width: $border-width * 2;
  border-bottom-width: calc(#{$card-border-radius} - #{$border-width * 2});
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: inherit;
  border-bottom-color: transparent;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

.card-border-lg::after {
  border-top-width: $border-width * 3;
  border-bottom-width: calc(#{$card-border-radius} - #{$border-width * 3});
}

.card-border-xl::after {
  border-top-width: $border-width * 4;
  border-bottom-width: calc(#{$card-border-radius} - #{$border-width * 3});
}


// Card row

.card-row {

  .card-body {

    @include media-breakpoint-up(md) {
      padding: $card-row-spacer-y $card-row-spacer-x;
    }
  }

  .card-meta {

    @include media-breakpoint-up(md) {
      padding-left: $card-row-spacer-x;
      padding-right: $card-row-spacer-x;
    }
  }
}


// Card image

.card-img-end {
  border-radius: $border-radius $border-radius 0 0;

  @include media-breakpoint-up(md) {
    border-radius: 0 $border-radius $border-radius 0;
  }
}

.card-img-start {
  border-radius: $border-radius $border-radius 0 0;

  @include media-breakpoint-up(md) {
    border-radius: $border-radius 0 0 $border-radius;
  }
}


// Card image slider

.card-img-slider {

  @include media-breakpoint-up(md) {
    height: 100%;
    width: 100%;

    * {
      height: inherit !important;
      width: inherit !important;
    }
  }
}


// Card image overlay

.card-img-overlay {
  display: flex;
  flex-direction: column;
  padding: $card-spacer-x;
}

.card-img-overlay .card-body {
  margin: -$card-spacer-x;
  margin-top: auto;
  border-bottom-right-radius: $card-inner-border-radius;
  border-bottom-left-radius: $card-inner-border-radius;
}


.card-img-overlay-hover {
  overflow: hidden;
}

.card-img-overlay-hover .card-body {
  transform: translateY(110%);
  transition: all .3s ease;
}

.card-img-overlay-hover:hover .card-body {
  transform: translateY(0);
}



// Card group

.card-group {

  @include media-breakpoint-only(sm) {
    flex-direction: column !important;

    > .card {
      flex: auto;
    }
  }
}


// Card meta

.card-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 $card-spacer-x $card-meta-spacer-y;
}

.card-meta-divider {
  width: 100%;
  margin: 0 0 $card-meta-spacer-y;
}


// Card button

.card-btn:last-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}


// Zoom

.card-zoom {
  overflow: hidden !important;
  border-radius: inherit;
}

.card-zoom > [class*="card-img"] {
  transition: all .3s ease;
  transform-origin: center center;
}

.card:hover > .card-zoom > [class*="card-img"] {
  transform: scale(1.1);
}


// Card list

.card-list .list-link {
  position: relative;
}

.card-list .list-link::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -$card-spacer-x;
  border-right: $border-width * 2 solid $primary;
  display: none;
}

.card-list .list-link:hover {
  color: $body-color !important;
}

.card-list .active .list-link {
  color: $body-color !important;
}

.card-list .active .list-link::before {
  display: block;
}


// Card bleed

@include media-breakpoint-down(md) {

  .card-bleed {
    width: auto;
    min-width: 100%;
    margin-right: -$grid-gutter-width / 2;
    margin-left: -$grid-gutter-width / 2;
    border-radius: 0;
  }

  .card-bleed .card-footer,
  .card-bleed .card-header,
  .card-bleed .card-body {
    padding-right: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width / 2;
    border-radius: 0;
  }

  .card-bleed .card-list .list-link::before {
    left: -$grid-gutter-width / 2;
  }
}
