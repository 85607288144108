//
// overlay.scss
// Theme utility
//

.overlay {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    border-radius: inherit;
  }
}


// Colors

@each $color, $value in $theme-colors {

  // Solid
  .overlay-#{$color} {
    @include overlay($value);
  }

  // Gradient
  .overlay-gradient-#{$color}-end {
    @include overlay-gradient($value, right);
  }
  .overlay-gradient-#{$color}-down {
    @include overlay-gradient($value, bottom);
  }
  .overlay-gradient-#{$color}-start {
    @include overlay-gradient($value, left);
  }
}


// Opacity

@for $i from 1 through 9 {
  .overlay-#{$i * 10} {

    &::before {
      opacity: #{$i / 10};
    }
  }
}