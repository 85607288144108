//
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
$font-family-base: "Euclid Circular A", "Roboto", "Helvetica Neue", Arial,
  sans-serif;

$primary: #d90429 !default;
$secondary: #ef233c !default;
